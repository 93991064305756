import Vue from 'vue'
import App from './App.vue'
// import * as L from 'leaflet'
// import 'leaflet/dist/leaflet.css'
import request from "@/utils/request"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'swiper/css/swiper.css';
import AVPlugin from "vue-audio-visual";

Vue.use(AVPlugin);
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.request=request

new Vue({
  render: h => h(App),
}).$mount('#app')
