<template>
    <div id="app">
        <div class="top-box">
            <div class="top-box-con" v-if="isFind">
                <ul id="myList">
                    <li v-for="(item,i) in markerData" :class="active==i?'active':''" :key="i" @click="showMarker(i)">
                        {{item.typeName}}
                    </li>
                </ul>
                <div class="find-btn" @click="showFind"><img src="./assets/icon-search.png" alt=""></div>
            </div>
            <div class="find-con" v-else>
                <input type="text" placeholder="请输入关键词搜索" v-model="content" @input="startFind"/>
                <img src="./assets/find1.png" alt=""/>
                <i v-if="isDelFind" class="el-icon-error close-find" @click="clearFind"></i>
            </div>
        </div>
        <div class="vr-box" v-show="isVr">
            <iframe :src="markerInfo.panoramaUrl" frameborder="0"></iframe>
            <div @click="isVr=false">×</div>
        </div>
        <audio ref="audio" :src="aUrl" preload></audio>
        <div class="find-result-box" v-if="isFindRes">
            <div class="res-item" v-if="findRes&&findRes.length>0 ">
                <div v-for="(item,i) in findRes" :key="i" @click="showContent(item.mapPointId)">
                    {{item.mapPointName}}
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <div v-else>
                <el-empty style="padding: 20px 0" description="此关键词无搜索结果!" :image-size="50"></el-empty>
            </div>
        </div>
<!--        <div class="btn-box">-->
<!--            <div>景点<br>列表</div>-->
<!--        </div>-->
        <transition name="fade">
            <div class="content-bg" v-show="showCon">
                <div class="content-box">
                    <div class="box-close" @click="handleClose"></div>
                    <div class="content-top">
                        <h3>{{markerInfo.mapPointName}}</h3>
                    </div>
                    <div class="content-img">
                        <img v-for="(item,i) in markerInfo.photos" :src="item" :key="i" alt="">
                    </div>
                    <div class="content-info">
<!--                        <h4>简介</h4>-->
                        <p>{{markerInfo.pointIntroduce?markerInfo.pointIntroduce:"暂无简介!"}}</p>
                    </div>
                </div>
            </div>
        </transition>
        <div id="map"></div>
    </div>
</template>

<script>
    import L from "leaflet";
    import 'leaflet/dist/leaflet.css';
    import icon1 from "./assets/po1.png";
    import icon2 from "./assets/po2.png";
    import icon3 from "./assets/po3.png";
    import icon4 from "./assets/po4.png";
    import icon5 from "./assets/po5.png";
    import icon6 from "./assets/po6.png";
    import icon7 from "./assets/po7.png";
    import "leaflet.markercluster/dist/MarkerCluster.css";
    import "leaflet.markercluster/dist/MarkerCluster.Default.css";
    import "leaflet.markercluster";
    import "./utils/L.TileLayer.Zoomify"
    import mapImage from "./assets/demo.jpg";

    export default {
        name: 'App',
        data() {
            return {
                map: null,
                isFind: true,
                center: [25.7781987236, 116.3010788849],
                zoom: 11,
                minZoom: 11,
                maxZoom: 15,
                zoomControl: false,
                attributionControl: false,
                corner1: L.latLng(26.0998005697, 116.0014843941),
                corner2: L.latLng(25.2711660552, 116.6926145554),
                imageBounds: [[26.0998005697, 116.0014843941], [25.2711660552, 116.6926145554]],
                markerData: [],
                markerDetail: [],
                layers: [],
                myGroup: [],
                active: 0,
                showCon: false,
                content: "",
                isVr: false,
                isFindRes: false,
                findRes: [],
                aUrl: "",
                isDelFind: false,
                markerInfo: {},
                myIcon: L.icon({
                    iconUrl: icon1,
                    iconSize: [35, 35],
                    iconAnchor: [35, 35],
                    popupAnchor: [-3, -30],
                    markerClusterLayer: [],
                }),
                items: [
                    {id: 1, imgUrl: "http://ct.newpais.com/image/e2.jpg"}
                ],
                swiperOptions: {
                    autoplay: true,
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination'
                    },
                    navigation: {
                        nextE1: '.swiper-button-next',
                        prevE1: '.swiper-button-prev'
                    }
                },
                markerName: "",
            };
        },
        created() {
            window.showContent = this.showContent;
            window.openLocation = this.openLocation;
            window.showVr = this.showVr;
            window.showAudio = this.showAudio;
            this.getData();
        },
        mounted() {
            this.initDateNew();
            document.getElementById('app-cockpit-loading').remove();
        },
        methods: {
            getData() {
                this.request.post("https://lyct.newpais.com/zhly/api/mapPoint/pointlist").then((res) => {
                    this.markerData = res.data;
                    this.markerDetail = res.data[0].mapPointList;
                    // console.log(this.markerDetail);
                    this.initMarker(this.markerDetail);
                })
            },
            setMyIcon(num){
                if(num == 0){
                    this.myIcon = L.icon({
                        iconUrl: icon1,
                        iconSize: [35, 35],
                        iconAnchor: [35, 35],
                        popupAnchor: [-3, -30],
                        markerClusterLayer: [],
                    })
                }else if(num == 1){
                    this.myIcon = L.icon({
                        iconUrl: icon2,
                        iconSize: [35, 35],
                        iconAnchor: [35, 35],
                        popupAnchor: [-3, -30],
                        markerClusterLayer: [],
                    })
                }else if(num == 2){
                    this.myIcon = L.icon({
                        iconUrl: icon3,
                        iconSize: [35, 35],
                        iconAnchor: [35, 35],
                        popupAnchor: [-3, -30],
                        markerClusterLayer: [],
                    })
                }else if(num == 3){
                    this.myIcon = L.icon({
                        iconUrl: icon4,
                        iconSize: [35, 35],
                        iconAnchor: [35, 35],
                        popupAnchor: [-3, -30],
                        markerClusterLayer: [],
                    })
                }else if(num == 4){
                    this.myIcon = L.icon({
                        iconUrl: icon5,
                        iconSize: [35, 35],
                        iconAnchor: [35, 35],
                        popupAnchor: [-3, -30],
                        markerClusterLayer: [],
                    })
                }else if(num == 5){
                    this.myIcon = L.icon({
                        iconUrl: icon6,
                        iconSize: [35, 35],
                        iconAnchor: [35, 35],
                        popupAnchor: [-3, -30],
                        markerClusterLayer: [],
                    })
                }else if(num == 6){
                    this.myIcon = L.icon({
                        iconUrl: icon7,
                        iconSize: [35, 35],
                        iconAnchor: [35, 35],
                        popupAnchor: [-3, -30],
                        markerClusterLayer: [],
                    })
                }
            },
            initMarker(data) {
                const that = this;
                data.forEach((item) => {
                    let marker;
                    if (item.mapPointName != null) {
                        marker = L.marker([item.pointLatitude, item.pointLongitude], {
                            icon: this.myIcon,
                            title: "",
                            draggable: false//设置标记点是否可以拖动
                        }).bindTooltip(item.mapPointName, {
                            offset: L.point(-15, -50),
                            direction: 'center',
                            permanent: true//是否永久性的打开
                        })
                        .openTooltip()
                    } else {
                        marker = L.marker([item.pointLatitude, item.pointLongitude], {
                            icon: this.myIcon,
                            title: "",
                            draggable: false//设置标记点是否可以拖动
                        })
                    }
                    // marker.addEventListener("click", () =>
                    //     this.showContent(item.mapPointId)
                    // );
                    marker.addEventListener("click", () =>
                        that.handlerMarker(item.mapPointId)
                    );
                    this.layers.push(marker);
                })
                // 点聚合组合
                // this.markerClusterLayer.addLayers(this.layers);
                this.myGroup = L.layerGroup(this.layers);
                this.map.addLayer(this.myGroup);
            },
            handlerMarker(id){
                const data = {
                    mapPointId: id
                };
                this.request.post("https://lyct.newpais.com/zhly/api/mapPoint/pointIntroduce", data).then((res) => {
                    this.markerInfo = res.data;
                    // console.log(this.markerInfo);
                    let lat = this.markerInfo.pointLatitude;
                    let long = this.markerInfo.pointLongitude;
                    let title = this.markerInfo.mapPointName;
                    let addr = this.markerInfo.addr?this.markerInfo.addr:"暂无地址";
                    let info = this.markerInfo.pointIntroduce?this.markerInfo.pointIntroduce:"暂无简介";
                    this.aUrl = this.markerInfo.audio;
                    if(title){
                        L.popup(L.latLng(lat, long),{
                            offset: L.point(-15, -20),
                            content: '<h3 class="pop-title">'+this.markerInfo.mapPointName+'</h3><p class="pop-content">'+info+'</p><div class="pop-menu">' +
                                '<div class="pop-btn-box pop-btn1" onClick="showContent('+id+')"><img src="http://ct.newpais.com/image/pop1.png" alt="">图文介绍</div>' +
                                '<div class="pop-btn-box pop-btn2" onClick="showAudio()"><img id="myAuImg" src="http://ct.newpais.com/image/pop2.png" alt="">语音讲解</div></div><div class="pop-menu">' +
                                '<div class="pop-btn-box pop-btn3" onClick="showVr()"><img src="http://ct.newpais.com/image/pop3.png" alt="">全景游览</div>' +
                                '<div class="pop-btn-box pop-btn4" onClick="openLocation('+lat+','+long+',\''+title+'\',\''+addr+'\')"><img src="http://ct.newpais.com/image/pop4.png" alt="">前往此处</div></div>'
                        }).openOn(this.map)
                    }else{
                        L.popup(L.latLng(lat, long),{
                            offset: L.point(-15, -20),
                            content: '<div class="pop-menu"><div style="margin-top: 25px" class="pop-btn-box pop-btn4" onClick="openLocation('+lat+','+long+',\''+title+'\',\''+addr+'\')"><img src="http://ct.newpais.com/image/pop4.png" alt="">前往此处</div></div>'
                        }).openOn(this.map)
                    }
                })
            },
            showVr(){
                if(this.markerInfo.panoramaUrl){
                    this.isVr = true;
                }else{
                    this.$message({
                        message: '暂无VR游览!',
                        offset: 70,
                        center: true
                    });
                }
            },
            showAudio(){
                if(this.aUrl){
                    let myAuImg = document.getElementById("myAuImg");
                    if(this.$refs.audio.paused){
                        myAuImg.src="http://ct.newpais.com/image/au.gif";
                        this.$refs.audio.play(true);
                    }else{
                        myAuImg.src="http://ct.newpais.com/image/pop2.png";
                        this.$refs.audio.pause();
                    }
                }else{
                    this.$message({
                        message: '暂无语音讲解!',
                        offset: 70,
                        center: true
                    });
                }
            },
            startFind() {
                this.isFindRes = true;
                if(this.content!=""){
                    this.isDelFind = true;
                }else{
                    this.isDelFind = false;
                }
                const data = {
                    mapPointName: this.content
                };
                this.request.post("https://lyct.newpais.com/zhly/api/mapPoint/query", data).then((res) => {
                    this.findRes = res.data;
                    // console.log(res.data)
                })
            },
            clearFind(){
                this.content = '';
                this.findRes = [];
            },
            //根据经纬度打开定位
            openLocation(lat, lon, title, addr){
                var mapUrl_tx = "http://apis.map.qq.com/uri/v1/marker?marker=coord:"+lat+","+lon+";title:" + title + ";addr:" + addr + "&referer=yellowpage";
                window.open(mapUrl_tx)
            },
            //清空组里面所有的标记点
            clearMarker() {
                this.map.removeLayer(this.myGroup);
                // this.markerClusterLayer.clearLayers();//清楚所有标记点
            },
            showContent(id) {
                this.audioUrlList = [];
                const data = {
                    mapPointId: id
                };
                this.request.post("https://lyct.newpais.com/zhly/api/mapPoint/pointIntroduce", data).then((res) => {
                    this.markerInfo = res.data;
                    const blob = new Blob([res.data.audio], { type: 'audio/wav' });
                    const audioUrl = URL.createObjectURL(blob);
                    this.audioUrlList.push(audioUrl);
                    // this.audioUrlList.push(res.data.audio);
                    // console.log(this.markerInfo);
                })
                this.showCon = true;
                // this.map.flyTo([item.pointLatitude,item.pointLongitude], 13);
            },
            handleClose(){
                this.showCon = false;
            },
            showFind(){
              this.isFind = false;
            },
            callNum(num){
                window.location.href = 'tel://' + num;
            },
            initDateNew() {
                //设置地图的边界，平移超出后会弹回
                const bounds = L.latLngBounds(this.corner1, this.corner2);
                this.map = L.map('map', {
                    center: this.center,
                    zoom: this.zoom,
                    minZoom: this.minZoom,
                    maxZoom: this.maxZoom,
                    zoomControl: this.zoomControl,
                    attributionControl: this.attributionControl,
                    maxBounds: bounds
                });
                L.imageOverlay(mapImage, this.imageBounds).addTo(this.map);
                // var nameFormat = "{x}_{y}";
                // var ext = ".png";
                // L.TileLayer.T = L.TileLayer.extend({
                //     getTileUrl: function (c) {
                //         var name = nameFormat
                //             .replace("{x}", c.x)
                //             .replace("{y}", c.y)
                //             .replace("{z}", c.z);
                //         return 'https://zhly.newpais.com/static/img/tiles/' + c.z + '/' + name + ext;
                //     }
                // });
                // new L.TileLayer.T("", { minZoom: 11, maxZoom: 15, zIndex: 999999 }).addTo(this.map);
                // var layer = L.tileLayer.zoomify('http://thematicmapping.org/playground/zoomify/books/{g}/{z}-{x}-{y}.jpg', {
                //     width: 5472,
                //     height: 3648,
                //     attribution: '&copy; Photo: Bjørn Sandvik'
                // }).addTo(this.map);
                // this.map.fitBounds(layer.getBounds());
                this.map.addEventListener("click", () => {
                    this.isFind = true;
                    this.isFindRes = false;
                });
                this.markerClusterLayer = L.markerClusterGroup({
                    showCoverageOnHover: false, // 为true时,当鼠标悬停在点上时，它会显示它聚合的边界
                    zoomToBoundsOnClick: true, //  为true时,当鼠标点击某个点时，会缩放到它的边界范围
                    chunkedLoading: true,
                    maxClusterRadius: 50, // 聚类从中心标记覆盖的最大半径（以像素为单位）,默认值 80
                    iconCreateFunction: function (cluster) {
                        return L.divIcon({html: '<div class="my-custom-icon"><i class="el-icon-location"></i>' + cluster.getChildCount() + '个</div>'});
                    }
                }).addTo(this.map);
            },
            showMarker(i) {
                this.active = i;
                this.showCon = false;
                this.layers = [];
                this.clearMarker();
                this.setMyIcon(i);
                this.map.flyTo(this.center, this.zoom);
                this.markerDetail = this.markerData[i].mapPointList;
                this.initMarker(this.markerDetail);
            }
        }
    }
</script>

<style>
    * {
        margin: 0;
        padding: 0;
    }

    #app {
        width: 100vw;
        height: 100vh;
    }

    html {
        font-size: 13px;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    #map {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    /*.find-box{*/
    /*    box-sizing: border-box;*/
    /*    position: absolute;*/
    /*    top: 0;*/
    /*    left: 0;*/
    /*    z-index: 3;*/
    /*    width: 100%;*/
    /*    height: 50px;*/
    /*    background: #03b9bd;*/
    /*    display: flex;*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*}*/

    .top-box {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;
        width: 100%;
        height: 50px;
        background: #03b9bd;
        display: flex;
        justify-content: space-around;
        align-items: center;
        /*border-radius: 10px;*/
    }

    .find-con {
        position: relative;
        box-sizing: border-box;
        width: calc(100% - 30px);
    }

    .find-con input {
        box-sizing: border-box;
        width: 100%;
        height: 38px;
        border: none;
        outline: none;
        font-size: 15px;
        padding: 0 35px 0 35px;
        border-radius: 30px;
    }

    .find-con img {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
    }

    .find-btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        background: #409B9D;
        color: white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .find-btn img{
        width: 20px;
        height: 20px;
    }

    .top-box-con {
        box-sizing: border-box;
        overflow-x: scroll;
        overflow-y: hidden;
        margin-right: 50px;
    }

    .top-box-con ul {
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        list-style: none;
        color: white;
        padding: 0 10px;
    }

    .top-box-con ul li {
        height: 100%;
        white-space: nowrap;
        padding: 5px 20px;
        cursor: pointer;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
    }

    .active {
        position: relative;
        /*font-size: 18px !important;*/
        font-weight: 600;
        background: white;
        color: black;
    }

    .leaflet-tooltip {
        padding: 0 12px;
        border-radius: 10px;
        background: white;
        color: black;
        border: 1px solid silver;
    }

    .leaflet-tooltip::before {
        display: none;
    }

    /*弹出内容*/
    .leaflet-popup-tip{
        height: 10px;
    }

    .leaflet-popup-content{
        margin: 8px 10px;
    }

    .leaflet-container a.leaflet-popup-close-button{
        top: 5px;
        right: 5px;
    }

    .pop-title{
        margin-bottom: 10px;
        font-size: 14px;
        margin-right: 10px;
        max-width: 180px;
    }

    .pop-content{
        text-align: justify;
        max-width: 180px;
        display: -webkit-box;				/*弹性伸缩盒子*/
        -webkit-box-orient: vertical;		/*子元素垂直排列*/
        line-clamp:2;
        -webkit-line-clamp:2;
        overflow: hidden;
        text-overflow: ellipsis;

    }

    .pop-menu{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pop-btn-box{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85px;
        height: 30px;
        line-height: 30px;
        margin-right: 5px;
        margin-bottom: 5px;
        text-align: center;
        color: white;
        border-radius: 5px;
        cursor: pointer;
    }

    .pop-btn-box img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }

    .pop-btn1{
        background: #4DB6BC;
    }

    .pop-btn2{
        background: #F0B23C;
    }

    .pop-btn3{
        background: #629AF8;
    }

    .pop-btn4{
        background: #56999D;
    }

    .content-bg{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.45);
    }

    .content-box {
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: calc(100% - 60px);
        max-width: 350px;
        max-height: 400px;
        padding: 20px;
        background: white;
        border-radius: 10px;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .content-box audio {
        width: 100%;
        margin: 10px 0;
    }

    .content-top {
        position: relative;
        margin-bottom: 10px;
    }

    .content-top h3 {
        font-size: 1.8rem;
        line-height: 2rem;
        width: calc(100% - 50px);
        /*overflow: hidden;*/
        /*white-space: nowrap;*/
        /*text-overflow: ellipsis;*/
    }

    .box-close{
        position: fixed;
        top: 0;
        right: 0;
        transform: translateX(50%) translateY(-50%);
        width: 70px;
        height: 70px;
        background: #03b9bd;
        border-radius: 50%;
    }

    .box-close::after{
        content: "×";
        position: absolute;
        left: 3px;
        bottom: 3px;
        width: 35px;
        text-align: center;
        line-height: 35px;
        color: white;
        font-size: 23px;
    }

    .content-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 25px 0;
    }

    .content-btn div{
        box-sizing: border-box;
        padding: 5px 15px;
        margin: 0 5px;
        border-radius: 30px;
        color: white;
        background: #03b9bd;
        font-size: 13px;
    }

    .content-btn div i {
        font-size: 15px;
        margin-right: 5px;
    }

    .content-img{
        width: 100%;
        white-space: nowrap;
        margin: 15px 0;
        overflow-y: hidden;
        overflow-x: scroll;
    }

    .content-img img{
        width: 80px;
        height: 80px;
        margin-right: 8px;
        border-radius: 10px;
    }

    .content-info h4 {
        font-size: 1.6rem;
        font-weight: 600;
        margin: 10px 0;
        text-align: center;
    }

    .content-info p {
        text-align: justify;
        font-size: 15px;
        line-height: 1.8rem;
        color: gray;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .fade-enter-to,
    .fade-leave-from {
        opacity: 1;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 1s ease;
    }

    .leaflet-div-icon {
        background: transparent;
        border: none;
    }

    .my-custom-icon {
        box-sizing: border-box;
        background: #E3811A;
        width: 50px;
        height: 22px;
        border-radius: 30px;
        color: white;
        text-align: center;
        line-height: 22px;
        font-size: 12px;
        box-shadow: 1px 2px 2px gray;
    }

    .my-custom-icon i {
        font-size: 14px;
        line-height: 25px;
        margin-right: 1px;
    }

    .vr-box{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1001;
        width: 100%;
        height: 100%;
        background: black;
    }

    .vr-box iframe{
        width: 100%;
        height: 100%;
    }

    .vr-box div{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 30px;
        height: 30px;
        background: white;
        border-radius: 50%;
        font-size: 22px;
        line-height: 30px;
        text-align: center;
    }

    .find-result-box{
        position: fixed;
        top: 50px;
        left: 0;
        z-index: 99;
        width: 100%;
        height: 150px;
        background: white;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .res-item div{
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        padding: 0 15px;
        line-height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #E8EAEA;
    }

    .close-find{
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        font-size: 15px;
    }

    .btn-box{
        box-sizing: border-box;
        position: fixed;
        bottom: 15px;
        right: 15px;
        z-index: 999;
        width: 45px;
        height: 45px;
    }

    .btn-box div{
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background: #03b9bd;
        border: 2px solid white;
        color: white;
        text-align: center;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
